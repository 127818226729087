/* eslint-disable max-lines */
import { useAppDispatch, useAppSelector } from '@/hooks';
import {
  claimSolRequest,
  getListWalletBuyPumpRequest,
  getListWalletSellPumpRequest,
  getListWalletVolumePumpRequest,
  getPumpBootTxListRequest,
  getPumpBootVolumeTxListRequest,
  importPumpBootTxRequest,
  importPumpBootVolumeTxRequest,
  importWalletBuyPumpRequest,
  importWalletSellPumpRequest,
  importWalletVolumePumpRequest,
  startFakeBuyPumpRequest,
  startFakeSellPumpRequest,
  startFakeVolumePumpRequest,
  updateVolumeBuyPumpRequest,
  updateVolumeSellPumpRequest,
  updateVolumeVolumePumpRequest,
  resetPumpInfoRequestStatus,
} from '@/stores/slices/pump/pump';

import { selectPump } from '@/stores/slices/pump/selector';

const usePumpHook: any = () => {
  const {
    importWalletBuyPumpStatus,
    importWalletSellPumpStatus,
    importWalletVolumePumpStatus,
    getListWalletBuyPumpStatus,
    getListWalletSellPumpStatus,
    getListWalletVolumePumpStatus,
    startFakeBuyPumpStatus,
    startFakeSellPumpStatus,
    startFakeVolumePumpStatus,
    updateVolumeBuyPumpStatus,
    updateVolumeSellPumpStatus,
    updateVolumeVolumePumpStatus,
    getPumpBootTxListStatus,
    importPumpBootTxStatus,
    getPumpBootVolumeTxListStatus,
    importPumpBootVolumeTxStatus,
    claimSolStatus,
    listWalletBuy,
    listWalletSell,
    listWalletVolume,
    pumpBootTxList,
    pumpBootVolumeTxList,
    listSignaturePumpBuy,
    listSignaturePumpSell,
    listSignaturePumpVolume,
  } = useAppSelector(selectPump);

  const dispatch = useAppDispatch();

  const requestImportWalletBuy = (params: any) =>
    dispatch(importWalletBuyPumpRequest(params));

  const requestImportWalletSell = (params: any) =>
    dispatch(importWalletSellPumpRequest(params));

  const requestImportWalletVolume = (params: any) =>
    dispatch(importWalletVolumePumpRequest(params));

  const requestGetListWalletBuyPump = (params: any) =>
    dispatch(getListWalletBuyPumpRequest(params));

  const requestGetListWalletSellPump = (params: any) =>
    dispatch(getListWalletSellPumpRequest(params));

  const requestGetListWalletVolumePump = (params: any) =>
    dispatch(getListWalletVolumePumpRequest(params));

  const requestPumpStartFakeBuy = (params: any) =>
    dispatch(startFakeBuyPumpRequest(params));

  const requestPumpStartFakeSell = (params: any) =>
    dispatch(startFakeSellPumpRequest(params));

  const requestPumpStartFakeVolume = (params: any) =>
    dispatch(startFakeVolumePumpRequest(params));

  const requestPumpUpdateVolumeBuy = (params: any) =>
    dispatch(updateVolumeBuyPumpRequest(params));
  const requestPumpUpdateVolumeSell = (params: any) =>
    dispatch(updateVolumeSellPumpRequest(params));
  const requestPumpUpdateVolumeVolume = (params: any) =>
    dispatch(updateVolumeVolumePumpRequest(params));
  const requestPumpBootTxList = (params: any) =>
    dispatch(getPumpBootTxListRequest(params));

  const requestImportPumpBootTx = (params: any) =>
    dispatch(importPumpBootTxRequest(params));

  const requestPumpBootVolumeTxList = (params: any) =>
    dispatch(getPumpBootVolumeTxListRequest(params));

  const requestPumpImportBootVolumeTx = (params: any) =>
    dispatch(importPumpBootVolumeTxRequest(params));

  const requestResetPump = () => dispatch(resetPumpInfoRequestStatus());

  const requestClaimSol = (params: any) => dispatch(claimSolRequest(params));

  return {
    requestResetPump,
    requestImportWalletBuy,
    requestImportWalletSell,
    requestImportWalletVolume,
    requestGetListWalletBuyPump,
    requestGetListWalletSellPump,
    requestGetListWalletVolumePump,
    requestPumpStartFakeBuy,
    requestPumpStartFakeSell,
    requestPumpStartFakeVolume,
    requestPumpUpdateVolumeBuy,
    requestPumpUpdateVolumeSell,
    requestPumpUpdateVolumeVolume,
    requestPumpBootTxList,
    requestImportPumpBootTx,
    requestPumpBootVolumeTxList,
    requestPumpImportBootVolumeTx,
    requestClaimSol,
    importWalletBuyPumpStatus,
    importWalletSellPumpStatus,
    importWalletVolumePumpStatus,
    getListWalletBuyPumpStatus,
    getListWalletSellPumpStatus,
    getListWalletVolumePumpStatus,
    startFakeBuyPumpStatus,
    startFakeSellPumpStatus,
    startFakeVolumePumpStatus,
    updateVolumeBuyPumpStatus,
    updateVolumeSellPumpStatus,
    updateVolumeVolumePumpStatus,
    getPumpBootTxListStatus,
    getPumpBootVolumeTxListStatus,
    importPumpBootVolumeTxStatus,
    importPumpBootTxStatus,
    claimSolStatus,
    listWalletBuy,
    listWalletSell,
    listWalletVolume,
    pumpBootTxList,
    pumpBootVolumeTxList,
    listSignaturePumpBuy,
    listSignaturePumpSell,
    listSignaturePumpVolume,
  };
};

export default usePumpHook;
