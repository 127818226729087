/* eslint-disable max-lines */
import Input from '@/components/form/input';
import NumberInput from '@/components/form/numberInput';
import { InputContainer } from '@/components/form/styled';
import TextArea from '@/components/form/textarea';
import { Title } from '@/components/login';
import { Box } from '@mui/material';
import { t } from 'i18next';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

export enum TraderType {
  'BUY' = 1,
  'SELL' = 2,
}
export interface BuyFormProps {
  onSubmit: (data: any) => void;
  type?: TraderType;
}

export function BuyForm({ onSubmit, type = TraderType.BUY }: BuyFormProps) {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<{
    buyPrivateKeys: string;
    contractAddress: string;
    volume?: number;
  }>({
    defaultValues: {
      buyPrivateKeys: '',
      contractAddress: '',
    },
  });

  const submitForm = (formDt: any) => {
    formDt.volume = formDt.volume?.toString().replace(/,/g, '');
    const buyPrivateKeys = formDt.buyPrivateKeys?.split(/\r?\n/) ?? [];
    onSubmit({
      ...formDt,
      volume: formDt.volume ? +formDt.volume : 0,
      buyPrivateKeys,
    });
  };

  useEffect(() => {
    reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box component="form" onSubmit={handleSubmit(submitForm)}>
      <Title>{t('label.pumpFunTrader')}</Title>
      {type === TraderType.BUY ? (
        <Box sx={{ '& span': { lineHeight: '20px' } }}>
          <Title>{t('label.buying')}</Title>
        </Box>
      ) : (
        <Title>{t('label.selling')}</Title>
      )}

      <InputContainer className="single">
        <Controller
          control={control}
          name="contractAddress"
          rules={{
            required: t('message.error.requiredField'),
          }}
          render={({ field }) => {
            const { value, onChange } = field;
            return (
              <Input
                label="Token"
                isRequired
                error={errors?.contractAddress?.message}
                value={value}
                onChange={onChange}
              />
            );
          }}
        />
      </InputContainer>

      <InputContainer className="single">
        <Controller
          control={control}
          name="buyPrivateKeys"
          rules={{
            required: t('message.error.requiredField'),
          }}
          render={({ field }) => {
            const { value, onChange } = field;
            return (
              <TextArea
                label={
                  type === TraderType.BUY
                    ? t('label.buyPrivateKey')
                    : t('label.sellPrivateKey')
                }
                isRequired
                error={errors?.buyPrivateKeys?.message}
                value={value}
                onChange={onChange}
              />
            );
          }}
        />
      </InputContainer>
      {type === TraderType.BUY ? (
        <InputContainer className="single">
          <Controller
            control={control}
            name="volume"
            rules={{
              required: t('message.error.requiredField'),
            }}
            render={({ field }) => {
              const { value, onChange } = field;
              return (
                <NumberInput
                  label={t('label.solAmount')}
                  value={value}
                  onChange={onChange}
                  isRequired
                  error={errors?.volume?.message}
                  placeholder={t('label.solAmount')}
                />
              );
            }}
          />
        </InputContainer>
      ) : (
        <InputContainer className="single">
          <Controller
            control={control}
            name="volume"
            rules={{
              required: t('message.error.requiredField'),
              max: {
                value: '100',
                message: 'must be no greater than 100 ',
              },
              min: {
                value: '1',
                message: 'must be no less than 1',
              },
            }}
            render={({ field }) => {
              const { value, onChange } = field;
              return (
                <NumberInput
                  label={'Selling percentage'}
                  value={value}
                  onChange={onChange}
                  isRequired
                  error={errors?.volume?.message}
                  placeholder="1 to 100"
                />
              );
            }}
          />
        </InputContainer>
      )}

      <Box
        component="div"
        sx={{
          margin: '40px 0 0',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <button
            className="main_btn button new_btn multi_color_btn"
            type="submit"
          >
            {type === TraderType.BUY ? 'Buy' : 'Sell'}
          </button>
        </Box>
      </Box>
    </Box>
  );
}
