/* eslint-disable max-lines */
import Pagination from '@/components/common/pagination';
import { ConfirmDeletePopup } from '@/components/token/components/confirmDeletePopup';
import { Box, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import { useMemo, useState } from 'react';

import { PaginationContainer } from './component';
import {
  BootTxBuySellHeader,
  BootTxHeader,
  CommonTableProps,
  PumpfunTokenHeader,
  SnipHeader,
  TableType,
  TokenHeader,
  TopHeader,
  TransactionHeader,
} from './table';
import {
  BootBuyTxRow,
  BootVolumeTxRow,
  ListTransactionRow,
  PumpfunTokenRow,
  SnipRow,
  TokenRow,
  TopRow,
  WalletSellRow,
} from './tableRow';

export const CommonTable = ({
  type,
  data,
  pagination,
  onDelete,
  onAction,
  onReload,
  control,
}: CommonTableProps) => {
  const [openConfirmDelete, setOpenConfirmDelete] = useState<boolean>(false);
  const [id, setId] = useState<number>(0);

  const handleShowDeleteTokenConfirm = (id: number) => {
    setId(id);
    setOpenConfirmDelete(true);
  };

  const handleDeleteToken = () => {
    onDelete && onDelete(id);
    onReload && onReload();
    setOpenConfirmDelete(false);
  };

  const renderTable = useMemo(() => {
    switch (type) {
      case TableType.PUMPFUN_TOKEN:
        return (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <PumpfunTokenHeader />
              {data?.map((row, index) => (
                <PumpfunTokenRow
                  key={row.id}
                  row={{ ...row, index }}
                  onDelete={handleShowDeleteTokenConfirm}
                />
              ))}
            </Table>
          </TableContainer>
        );
      case TableType.TOP:
        return (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TopHeader holders={data} />
              {data?.map((row, index) => (
                <TopRow key={row.id} row={{ ...row, index }} />
              ))}
            </Table>
          </TableContainer>
        );

      case TableType.VENUE:
        return (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TokenHeader />
              {data?.map((row, index) => (
                <TokenRow
                  key={row.id}
                  row={{ ...row, index }}
                  onDelete={handleShowDeleteTokenConfirm}
                />
              ))}
            </Table>
            {data?.length === 0 || !data ? (
              <Typography
                sx={{ background: '#3f3f3f', padding: '12px' }}
                fontSize={18}
                color={'#ccc'}
                textAlign={`center`}
              >
                No Data
              </Typography>
            ) : (
              <></>
            )}
          </TableContainer>
        );

      case TableType.DETAIL:
        return (
          <TableContainer
            component={Paper}
            sx={{
              '& a': {
                color: '#FF9900 ',
              },
            }}
          >
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <SnipHeader />
              {data?.map((row, index) => (
                <SnipRow key={row.id} row={{ ...row, index }} />
              ))}
            </Table>
          </TableContainer>
        );

      case TableType.BOOT_TX:
        return (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <BootTxHeader />
              {data?.map((row, index) => (
                <BootBuyTxRow
                  key={row.id}
                  row={{ ...row, index }}
                  type={TableType.BOOT_TX}
                />
              ))}
            </Table>
            {data?.length === 0 || !data ? (
              <Typography
                sx={{ background: '#3f3f3f', padding: '12px' }}
                fontSize={18}
                color={'#ccc'}
                textAlign={`center`}
              >
                No Data
              </Typography>
            ) : (
              <></>
            )}
          </TableContainer>
        );

      case TableType.PUMP_BOOT_TX:
        return (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <BootTxHeader />
              {data?.map((row, index) => (
                <BootBuyTxRow
                  key={row.id}
                  row={{ ...row, index }}
                  type={TableType.PUMP_BOOT_TX}
                />
              ))}
            </Table>
            {data?.length === 0 || !data ? (
              <Typography
                sx={{ background: '#3f3f3f', padding: '12px' }}
                fontSize={18}
                color={'#ccc'}
                textAlign={`center`}
              >
                No Data
              </Typography>
            ) : (
              <></>
            )}
          </TableContainer>
        );

      case TableType.BOOT_VOLUME:
        return (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <BootTxHeader />
              {data?.map((row, index) => (
                <BootVolumeTxRow
                  key={row.id}
                  row={{ ...row, index }}
                  type={TableType.BOOT_VOLUME}
                />
              ))}
            </Table>
            {data?.length === 0 || !data ? (
              <Typography
                sx={{ background: '#3f3f3f', padding: '12px' }}
                fontSize={18}
                color={'#ccc'}
                textAlign={`center`}
              >
                No Data
              </Typography>
            ) : (
              <></>
            )}
          </TableContainer>
        );

      case TableType.PUMP_BOOT_VOLUME:
        return (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <BootTxHeader />
              {data?.map((row, index) => (
                <BootVolumeTxRow
                  key={row.id}
                  row={{ ...row, index }}
                  type={TableType.PUMP_BOOT_VOLUME}
                />
              ))}
            </Table>
            {data?.length === 0 || !data ? (
              <Typography
                sx={{ background: '#3f3f3f', padding: '12px' }}
                fontSize={18}
                color={'#ccc'}
                textAlign={`center`}
              >
                No Data
              </Typography>
            ) : (
              <></>
            )}
          </TableContainer>
        );

      case TableType.BOOT_BUY_SELL_TX:
        return (
          <TableContainer component={Paper} sx={{ maxHeight: 500 }}>
            <Table
              sx={{ minWidth: 650 }}
              aria-label="sticky table"
              stickyHeader
            >
              <BootTxBuySellHeader />
              {data?.map((row, index) => (
                <WalletSellRow
                  key={row.id}
                  row={{ ...row, index }}
                  control={control}
                />
              ))}
            </Table>
            {data?.length === 0 || !data ? (
              <Typography
                sx={{ background: '#3f3f3f', padding: '12px' }}
                fontSize={18}
                color={'#ccc'}
                textAlign={`center`}
              >
                No Data
              </Typography>
            ) : (
              <></>
            )}
          </TableContainer>
        );

      case TableType.PUMP_BOOT_BUY_SELL_TX:
        return (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <BootTxBuySellHeader />
              {data?.map((row, index) => (
                <WalletSellRow
                  key={row.id}
                  row={{ ...row, index }}
                  control={control}
                />
              ))}
            </Table>
            {data?.length === 0 || !data ? (
              <Typography
                sx={{ background: '#3f3f3f', padding: '12px' }}
                fontSize={18}
                color={'#ccc'}
                textAlign={`center`}
              >
                No Data
              </Typography>
            ) : (
              <></>
            )}
          </TableContainer>
        );

      case TableType.LIST_TRANSACTION:
        return (
          <TableContainer
            component={Paper}
            sx={{ maxHeight: 440, background: '#fff !important' }}
          >
            <Table
              sx={{ minWidth: 650 }}
              aria-label="sticky table"
              stickyHeader
            >
              <TransactionHeader />
              {data?.map((row, index) => (
                <ListTransactionRow key={row.id} row={{ ...row, index }} />
              ))}
            </Table>
            {data?.length === 0 || !data ? (
              <Typography
                sx={{ background: '#3f3f3f', padding: '12px' }}
                fontSize={18}
                color={'#ccc'}
                textAlign={`center`}
              >
                No Data
              </Typography>
            ) : (
              <></>
            )}
          </TableContainer>
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps, no-sparse-arrays
  }, [type, data, onDelete]);

  return (
    <Box>
      <TableContainer
        className={type === TableType.TOP ? 'top' : ''}
        sx={{
          '& .MuiTableCell-root': {
            color: '#fff',
            background: '#2c2d30',
            borderBottom: 'none',
          },
          '& td.MuiTableCell-root': {
            color: '#fff',
            background: '#3f3f3f',
            borderBottom: 'none',
          },
          '& tr.MuiTableRow-root:hover .MuiTableCell-root': {
            background: '#2c2d30',
          },
        }}
      >
        {renderTable}
      </TableContainer>
      {pagination && (
        <PaginationContainer>
          <Pagination {...pagination} />
        </PaginationContainer>
      )}
      <ConfirmDeletePopup
        open={openConfirmDelete}
        onSubmit={handleDeleteToken}
        onClose={() => setOpenConfirmDelete(false)}
      />
    </Box>
  );
};
