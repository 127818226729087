import {
  getToken,
  removeToken,
  removeUserCachedDataFromLocalStorage,
} from '@/utils/localStorage';
import type { Method as AxiosMethod } from 'axios';
import axios, { AxiosHeaders } from 'axios';

import ToastMessage from '@/components/ToastMessage';
import { AppConfig } from '@/config';
import { TOKEN_ACCESS_KEY } from '@/constants';
import Path from '@/constants/path';
import _ from 'lodash';

export type ESystemMethod = Extract<
  AxiosMethod,
  | 'get'
  | 'GET'
  | 'delete'
  | 'DELETE'
  | 'post'
  | 'POST'
  | 'put'
  | 'PUT'
  | 'patch'
  | 'PATCH'
  | 'options'
  | 'OPTIONS'
>;

const mainRequestConfig = {
  // Mock baseURL is from a local Postman Mock Server
  baseURL: AppConfig.apiBase,
  headers: {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    Authorization: `Bearer ${localStorage.getItem(TOKEN_ACCESS_KEY)}`,
  },
};

const mainAxiosInstance = axios.create(mainRequestConfig);

mainAxiosInstance.interceptors.request.use(
  config => {
    const token = getToken();
    if (token)
      config.headers.Authorization = `Bearer ${localStorage.getItem(
        TOKEN_ACCESS_KEY,
      )}`;
    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

mainAxiosInstance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    const { status, data } = error.response;
    if (status === 401) {
      removeToken();
      removeUserCachedDataFromLocalStorage();
      window.location.href = Path.LOGIN;
      return Promise.resolve(error);
    }
    if (status === 403) {
      // window.location.href = Path.UNKNOWN;
      removeToken();
      window.location.href = Path.LOGIN;
    }
    if (status === 400 || status === 422) {
      ToastMessage.error(data.message);
    }
    return error.response;
  },
);

export const mainRequest = (
  url: string,
  payload: any,
  method: ESystemMethod,
  headers: AxiosHeaders = new AxiosHeaders({
    'X-Requested-With': 'XMLHttpRequest',
  }),
) => {
  const data = payload;
  let params;
  if (method === 'get') {
    params = payload;
  }
  return mainAxiosInstance(url, { data, params, method, headers });
};

const SERVICES = {
  /* <AUTHEN> */
  login: (payload: any) => mainRequest('/auth/login', payload, 'post'),
  signUp: (payload: any) => mainRequest('/auth/sign-up', payload, 'post'),
  register: (payload: any) => mainRequest('/auth/register', payload, 'post'),
  getProfile: (id: number) => mainRequest(`/profile/${id}/`, {}, 'get'),
  updateProfile: (payload: any) =>
    mainRequest(`/profile/${payload.id}/`, payload, 'put'),

  /* <TOKEN> */
  getTokenOptions: (payload: any) =>
    mainRequest('/token-options/', payload, 'get'),
  getTokens: (payload: any) => mainRequest('/token', payload, 'get'),
  refreshTokens: (payload: any) =>
    mainRequest(`/holder/${payload.id}`, {}, 'post'),
  revokeFreezeTokens: (payload: any) =>
    mainRequest(`/token/${payload}/freeze/revoke`, {}, 'post'),
  revokeMintTokens: (payload: any) =>
    mainRequest(`/token/${payload}/mint/revoke`, {}, 'post'),
  burnTokens: (payload: any) =>
    mainRequest(`/token/${payload.id}/burn`, {}, 'post'),
  sendSol: (payload: any) => mainRequest(`/transfer`, payload, 'post'),
  sendSolV2: (payload: any) => mainRequest(`/transfer/v2`, payload, 'post'),
  sendMultiSol: (payload: any) =>
    mainRequest(`/token/${payload.id}/multiSender`, payload, 'post'),
  snipSol: (payload: any) =>
    mainRequest(`/token/${payload.id}/snip`, payload, 'post'),
  getSnip: (payload: any) =>
    mainRequest(`/token/${payload.id}/snip`, {}, 'get'),
  rugPoolTokens: (payload: any) =>
    mainRequest(`/token/${payload.id}/removePoolV2`, {}, 'post'),
  getTokenHolders: (payload: any) =>
    mainRequest(`/holder/top/${payload.id}`, {}, 'get'),
  getTokenDetails: (payload: any) =>
    mainRequest(`/token/${payload.id}/`, {}, 'get'),
  createToken: (payload: any) => mainRequest(`/token`, payload, 'post'),
  createTokenAddress: (payload: any) =>
    mainRequest(`/wallet/import`, payload, 'post'),
  updateToken: (payload: any) =>
    mainRequest(
      `/token/${payload.id}/metadata`,
      _.omit(payload, ['id']),
      'post',
    ),

  deleteTokens: (payload: any) =>
    mainRequest(`/del-multi-tokens/`, payload, 'post'),
  openBook: (payload: any) =>
    mainRequest(`/token/${payload.id}/openbook`, payload, 'post'),
  openPool: (payload: any) =>
    mainRequest(`/token/${payload.id}/newPool`, payload, 'post'),
  openPoolV2: (payload: any) =>
    mainRequest(`/token/${payload.id}/newPoolV2`, payload, 'post'),
  deleteToken: (payload: any) =>
    mainRequest(`/token/${payload}/disable`, {}, 'post'),
  autoRugPoolTokens: (payload: any) =>
    mainRequest(`/pool/${payload.id}/auto-rug`, payload, 'post'),
  openBookMarket: (payload: any) => mainRequest(`/openbook`, payload, 'post'),
  getPumpFunList: (payload: any) => mainRequest('/pumpfun', payload, 'get'),
  createPumpFun: (payload: any) => mainRequest(`/pumpfun`, payload, 'post'),
  buyPumpFun: (payload: any) => mainRequest(`/pumpfun/buy`, payload, 'post'),
  sellPumpFun: (payload: any) => mainRequest(`/pumpfun/sell`, payload, 'post'),
  getPumpFunMint: () => mainRequest(`/pumpfun/mint`, {}, 'get'),
  multiTokenSend: (payload: any) =>
    mainRequest(`/distribution/multiSender`, payload, 'post'),
  importToken: (payload: any) => mainRequest(`/token/import`, payload, 'post'),
  getContractAddress: (payload: any) => mainRequest('/address', payload, 'get'),

  getBootTx: (payload: any) => mainRequest('/fake-token', payload, 'get'),
  importBootTx: (payload: any) => mainRequest(`/fake-token`, payload, 'post'),
  getBootVolumeTx: (payload: any) =>
    mainRequest('/fake-token/volume', payload, 'get'),
  importBootVolumeTx: (payload: any) =>
    mainRequest(`/fake-token/volume`, payload, 'post'),

  importWalletBuy: (payload: any) =>
    mainRequest(`/fake-wallet/buy/import`, payload, 'post'),
  importWalletSell: (payload: any) =>
    mainRequest(`/fake-wallet/sell/import`, payload, 'post'),
  importWalletVolume: (payload: any) =>
    mainRequest(`/fake-wallet/volume/import`, payload, 'post'),
  updateVolumeBuy: (payload: any) =>
    mainRequest(`/fake-wallet/volume/buy`, payload, 'put'),
  updateVolumeSell: (payload: any) =>
    mainRequest(`/fake-wallet/volume/sell`, payload, 'put'),
  updateVolumeVolume: (payload: any) =>
    mainRequest(`/fake-wallet/volume/volume`, payload, 'put'),
  getListWalletBuy: (payload: any) =>
    mainRequest(`/fake-wallet/buy`, payload, 'get'),
  getListWalletSell: (payload: any) =>
    mainRequest(`/fake-wallet/sell`, payload, 'get'),
  getListWalletVolume: (payload: any) =>
    mainRequest(`/fake-wallet/volume`, payload, 'get'),
  startFakeBuy: (payload: any) => mainRequest(`/fake-buy`, payload, 'post'),
  startFakeSell: (payload: any) => mainRequest(`/fake-sell`, payload, 'post'),
  startFakeVolume: (payload: any) =>
    mainRequest(`/fake-volume`, payload, 'post'),

  importWalletBuyPump: (payload: any) =>
    mainRequest(`/pump-wallet/buy/import`, payload, 'post'),
  importWalletSellPump: (payload: any) =>
    mainRequest(`/pump-wallet/sell/import`, payload, 'post'),
  importWalletVolumePump: (payload: any) =>
    mainRequest(`/pump-wallet/volume/import`, payload, 'post'),

  updateVolumeBuyPump: (payload: any) =>
    mainRequest(`/pump-wallet/volume/buy`, payload, 'put'),
  updateVolumeSellPump: (payload: any) =>
    mainRequest(`/pump-wallet/volume/sell`, payload, 'put'),
  updateVolumeVolumePump: (payload: any) =>
    mainRequest(`/pump-wallet/volume/volume`, payload, 'put'),

  getListWalletBuyPump: (payload: any) =>
    mainRequest(`/pump-wallet/buy`, payload, 'get'),
  getListWalletSellPump: (payload: any) =>
    mainRequest(`/pump-wallet/sell`, payload, 'get'),
  getListWalletVolumePump: (payload: any) =>
    mainRequest(`/pump-wallet/volume`, payload, 'get'),

  startFakeBuyPump: (payload: any) =>
    mainRequest(`/pump-wallet/fake-buy`, payload, 'post'),
  startFakeSellPump: (payload: any) =>
    mainRequest(`/pump-wallet/fake-sell`, payload, 'post'),
  startFakeVolumePump: (payload: any) =>
    mainRequest(`/pump-wallet/fake-volume`, payload, 'post'),

  getPumpBootTx: (payload: any) =>
    mainRequest('/pump-wallet/fake-token', payload, 'get'),
  importPumpBootTx: (payload: any) =>
    mainRequest(`/pump-wallet/fake-token`, payload, 'post'),
  getPumpBootVolumeTx: (payload: any) =>
    mainRequest('/pump-wallet/fake-token/volume', payload, 'get'),
  importPumpBootVolumeTx: (payload: any) =>
    mainRequest(`/pump-wallet/fake-token/volume`, payload, 'post'),

  claimSol: (payload: any) => mainRequest(`/transfer/claim`, payload, 'post'),
};

export default SERVICES;
