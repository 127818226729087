import './App.scss';
import './assets/scss/config/_variables.scss';
import './locales/i18n';
import './styles/index.css';

import { store } from '@/stores';
import * as Sentry from '@sentry/react';
import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import 'buffer';
import { Loader } from './components/common/loader';
import ProtectedRoute from './components/common/ProtectedRoute';
import { GlobalContainer } from './components/layouts/global';
import { TableType } from './components/layouts/table/table';
import Path from './constants/path';
import { BootBuySellTx } from './pages/bootBuySellTx';
import { BootTxPage } from './pages/bootTx';
import { ClaimSolPage } from './pages/claimSol';
import { CreateMarketPage } from './pages/createMarket';
import { CreatePoolPage } from './pages/createPool';
import { CreatePumpfunToken } from './pages/createPumpfunToken';
import { CreateTokenPage } from './pages/createToken';
import { GenerateKey } from './pages/generateKey';
import LoginPage from './pages/login';
import { MultiSenderPage } from './pages/multiSender';
import { PumpBootBuySellTx } from './pages/pumpBootBuySellTx';
import { PumpBootTxPage } from './pages/pumpBootTx';
import { PumpfunTokenList } from './pages/pumpFunTokens';
import { PumpfunTrader } from './pages/pumpfunTrader';
import { SendSolPage } from './pages/sendSol';
import SignUpPage from './pages/signUp';
import { TokenPage } from './pages/token';
import { TokenDetailsPage } from './pages/tokenDetails';
import reportWebVitals from './reportWebVitals';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

const router = createBrowserRouter([
  {
    path: Path.HOME,
    element: (
      <ProtectedRoute>
        <TokenPage />
      </ProtectedRoute>
    ),
  },
  {
    path: Path.GEN_WALLET,
    element: (
      <ProtectedRoute>
        <GenerateKey />
      </ProtectedRoute>
    ),
  },
  {
    path: Path.MULTI_SENDER,
    element: (
      <ProtectedRoute>
        <MultiSenderPage />
      </ProtectedRoute>
    ),
  },
  {
    path: Path.TOKEN_LIST,
    element: (
      <ProtectedRoute>
        <TokenPage />
      </ProtectedRoute>
    ),
  },
  {
    path: Path.PUMPFUN_TOKENS,
    element: (
      <ProtectedRoute>
        <PumpfunTokenList />
      </ProtectedRoute>
    ),
  },
  {
    path: Path.CREATE_PUMPFUN_TOKEN,
    element: (
      <ProtectedRoute>
        <CreatePumpfunToken />
      </ProtectedRoute>
    ),
  },
  {
    path: Path.PUMPFUN_TRADERS,
    element: (
      <ProtectedRoute>
        <PumpfunTrader />
      </ProtectedRoute>
    ),
  },
  {
    path: Path.SEND_SOL,
    element: (
      <ProtectedRoute>
        <SendSolPage />
      </ProtectedRoute>
    ),
  },
  {
    path: Path.TOKEN_DETAILS,
    element: (
      <ProtectedRoute>
        <TokenDetailsPage />
      </ProtectedRoute>
    ),
  },
  {
    path: Path.CREATE_TOKEN,
    element: (
      <ProtectedRoute>
        <CreateTokenPage />
      </ProtectedRoute>
    ),
  },
  {
    path: Path.CREATE_BOOK,
    element: (
      <ProtectedRoute>
        <CreateMarketPage />
      </ProtectedRoute>
    ),
  },
  {
    path: Path.CREATE_POOL,
    element: (
      <ProtectedRoute>
        <CreatePoolPage />
      </ProtectedRoute>
    ),
  },
  {
    path: Path.LOGIN,
    element: <LoginPage />,
  },
  {
    path: Path.SIGN_UP,
    element: <SignUpPage />,
  },
  {
    path: Path.BOOT_BUY,
    element: (
      <ProtectedRoute>
        <BootTxPage type={TableType.BOOT_TX} />
      </ProtectedRoute>
    ),
  },
  {
    path: Path.BOOT_VOLUME,
    element: (
      <ProtectedRoute>
        <BootTxPage type={TableType.BOOT_VOLUME} />
      </ProtectedRoute>
    ),
  },
  {
    path: Path.BOOT_BUY_TX,
    element: (
      <ProtectedRoute>
        <BootBuySellTx type="buy" />
      </ProtectedRoute>
    ),
  },
  {
    path: Path.BOOT_SELL_TX,
    element: (
      <ProtectedRoute>
        <BootBuySellTx type="sell" />
      </ProtectedRoute>
    ),
  },
  {
    path: Path.BOOT_VOLUME_TX,
    element: (
      <ProtectedRoute>
        <BootBuySellTx type="volume" />
      </ProtectedRoute>
    ),
  },
  {
    path: Path.BOOT_TX_PUMP,
    element: (
      <ProtectedRoute>
        <PumpBootTxPage type={TableType.PUMP_BOOT_TX} />
      </ProtectedRoute>
    ),
  },
  {
    path: Path.BOOT_VOLUME_PUMP,
    element: (
      <ProtectedRoute>
        <PumpBootTxPage type={TableType.PUMP_BOOT_VOLUME} />
      </ProtectedRoute>
    ),
  },
  {
    path: Path.BOOT_BUY_TX_PUMP,
    element: (
      <ProtectedRoute>
        <PumpBootBuySellTx type="buy" />
      </ProtectedRoute>
    ),
  },
  {
    path: Path.BOOT_SELL_TX_PUMP,
    element: (
      <ProtectedRoute>
        <PumpBootBuySellTx type="sell" />
      </ProtectedRoute>
    ),
  },
  {
    path: Path.BOOT_VOLUME_TX_PUMP,
    element: (
      <ProtectedRoute>
        <PumpBootBuySellTx type="volume" />
      </ProtectedRoute>
    ),
  },
  {
    path: Path.CLAIM_SOL,
    element: (
      <ProtectedRoute>
        <ClaimSolPage />
      </ProtectedRoute>
    ),
  },
]);

root.render(
  <Suspense fallback={<Loader show />}>
    <Provider store={store}>
      <GlobalContainer>
        <RouterProvider router={router} />
      </GlobalContainer>
    </Provider>
  </Suspense>,
);

// Hot reloadable translation json files
if (module.hot) {
  module.hot.accept(['./locales/i18n'], () => {
    // No need to render the App again because i18next works with the hooks
  });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
