/* eslint-disable max-lines */
import { EyeIcon } from '@/components/icons';
import { formatNumber, mappingSnipStatus } from '@/utils/helper';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import NumberInput from '@/components/form/numberInput';
import { Box, styled } from '@mui/material';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { LinkText, Text } from './component';
import { TableType } from './table';
import { FloorRowProps, TokenRowProps } from './types';

export const TopRow = ({ row }: FloorRowProps) => {
  return (
    <TableRow
      key={row.index}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell>{row.index + 1}</TableCell>
      <TableCell className="width_600">{row?.address}</TableCell>
      <TableCell className="width_400">
        {formatNumber(+row?.tokenBalance)}
      </TableCell>
      <TableCell className="width_100">{row?.percentage}</TableCell>
    </TableRow>
  );
};

export const SnipRow = ({ row }: FloorRowProps) => {
  return (
    <TableRow
      key={row.index}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell className="width_7">{row.index + 1}</TableCell>
      <TableCell className="width_500">{row?.wallet?.address}</TableCell>
      <TableCell className="width_15">
        {formatNumber(row?.amount ? row?.amount / Math.pow(10, 9) : 0)}
      </TableCell>
      <TableCell className="width_12">
        {mappingSnipStatus(row?.status)}
      </TableCell>
      <TableCell className="word_break">
        {row?.tx ? (
          <a
            href={`https://solscan.io/tx/${row?.tx}`}
            target="_blank"
            rel="noreferrer"
          >
            {row?.tx}
          </a>
        ) : (
          ''
        )}
      </TableCell>
    </TableRow>
  );
};

export const PumpfunTokenRow = ({ row, onDelete }: TokenRowProps) => {
  return (
    <TableRow
      key={row.index}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell>{row.index + 1}</TableCell>
      <TableCell className="width_50">{row?.name}</TableCell>
      {/* <TableCell className="p_8">
        {row?.image ? <img src={row?.image} alt={row?.name} /> : ''}
      </TableCell> */}
      <TableCell className="width_30">{row?.symbol}</TableCell>
      <TableCell className="width_200">{row?.contract_address}</TableCell>
      <TableCell className="width_100">{formatNumber(row?.supply)}</TableCell>
      <TableCell>
        <div className="header_button">
          <button
            className="main_btn multi_color_btn button new_btn danger"
            onClick={() => onDelete && onDelete(row.id)}
          >
            Delete
          </button>
          {/* <LinkText to={`/tokens/${row.id}`}> */}
          <EyeIcon />
          <Text>View</Text>
          {/* </LinkText> */}
        </div>
      </TableCell>
    </TableRow>
  );
};

export const TokenRow = ({
  row,
  onView,
  onDelete,
  onEdit,
  onSelect,
  selected,
}: TokenRowProps) => {
  return (
    <TableRow
      key={row.index}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell>{row.index + 1}</TableCell>
      <TableCell className="width_50">{row?.name}</TableCell>
      {/* <TableCell className="p_8">
        {row?.image ? <img src={row?.image} alt={row?.name} /> : ''}
      </TableCell> */}
      <TableCell className="width_30">{row?.symbol}</TableCell>
      <TableCell className="width_200">{row?.contract_address}</TableCell>
      <TableCell className="width_100">{formatNumber(row?.supply)}</TableCell>
      <TableCell>
        <div className="header_button">
          <button
            className="main_btn multi_color_btn button new_btn danger"
            onClick={() => onDelete && onDelete(row.id)}
          >
            Delete
          </button>
          <LinkText to={`/tokens/${row.id}`}>
            <EyeIcon />
            <Text>View</Text>
          </LinkText>
        </div>
      </TableCell>
    </TableRow>
  );
};

export const BootSellTxRow = ({ row }: TokenRowProps) => {
  return (
    <TableRow
      key={row.index}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell sx={{ width: '5%' }}>{row.index + 1}</TableCell>
      <TableCell sx={{ width: '55%' }}>{row?.contract_address}</TableCell>
      <TableCell sx={{ width: '15%' }}>
        <div className="header_button">
          {' '}
          <Box className="main_btn multi_color_btn button new_btn danger">
            <LinkText to={`/boot_sell_tx/${row.id}`}>
              <span>boot sell tx</span>
            </LinkText>
          </Box>
        </div>
      </TableCell>
    </TableRow>
  );
};

export const BootBuyTxRow = ({ row, type }: TokenRowProps) => {
  const handleClickBootBuyTx = () => {
    if (type === TableType.BOOT_TX) {
      localStorage.setItem('rootPath', 'Boot Buy/Sell TX');
    } else {
      localStorage.setItem('rootPath', 'Pump Boot Buy/Sell TX');
    }
  };
  return (
    <TableRow
      key={row.index}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell sx={{ width: '5%' }}>{row.index + 1}</TableCell>
      <TableCell sx={{ width: '55%' }}>{row?.contract_address}</TableCell>
      <TableCell sx={{ width: '25%' }}>
        <div className="header_button">
          {' '}
          <Box
            className="main_btn multi_color_btn button new_btn danger"
            onClick={handleClickBootBuyTx}
          >
            <LinkText
              to={
                type === TableType.BOOT_TX
                  ? `/raydium/boot_tx/buy/${row.id}`
                  : `/pumpfun/boot_tx/buy/${row.id}`
              }
            >
              <span>boot buy tx</span>
            </LinkText>
          </Box>
          <Box
            className="main_btn multi_color_btn button new_btn danger"
            onClick={handleClickBootBuyTx}
          >
            <LinkText
              to={
                type === TableType.BOOT_TX
                  ? `/raydium/boot_tx/sell/${row.id}`
                  : `/pumpfun/boot_tx/sell/${row.id}`
              }
            >
              <span>boot sell tx</span>
            </LinkText>
          </Box>
        </div>
      </TableCell>
    </TableRow>
  );
};

export const BootVolumeTxRow = ({ row, type }: TokenRowProps) => {
  const handleClickBootBuyTx = () => {
    if (type === TableType.BOOT_VOLUME) {
      localStorage.setItem('rootPath', 'Boot Volume TX');
    } else {
      localStorage.setItem('rootPath', 'Pump Boot Volume TX');
    }
  };
  return (
    <TableRow
      key={row.index}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell sx={{ width: '5%' }}>{row.index + 1}</TableCell>
      <TableCell sx={{ width: '55%' }}>{row?.contract_address}</TableCell>
      <TableCell sx={{ width: '15%' }}>
        <div className="header_button">
          {' '}
          <Box
            className="main_btn multi_color_btn button new_btn danger"
            onClick={handleClickBootBuyTx}
          >
            <LinkText
              to={
                type === TableType.BOOT_VOLUME
                  ? `/raydium/boot_tx/volume/${row.id}`
                  : `/pumpfun/boot_tx/volume/${row.id}`
              }
            >
              <span>boot volume tx</span>
            </LinkText>
          </Box>
        </div>
      </TableCell>
    </TableRow>
  );
};

export const WalletSellRow = ({ row, control }) => {
  const { t } = useTranslation();
  return (
    <TableRow
      key={row.index}
      sx={{ '&:last-child td, &:last-child th': { border: 0 }, width: '100%' }}
    >
      <TableCell sx={{ width: '5%' }}>{row.index + 1}</TableCell>
      <TableCell sx={{ width: '75%' }}>{row?.address}</TableCell>
      <TableCell sx={{ width: '20%' }}>
        <div className="header_button">
          <Controller
            control={control}
            name={`volume${row.id}`}
            rules={{
              required: t('message.error.requiredField'),
            }}
            render={({ field }) => {
              const { value, onChange } = field;
              return (
                <NumberInput
                  label={''}
                  // error={errors?.ownerPrivateKey?.message}
                  value={value}
                  onChange={onChange}
                  max={0.01}
                  noBottom
                />
              );
            }}
          />
        </div>
      </TableCell>
    </TableRow>
  );
};

export const ListTransactionRow = ({ row }) => {
  const { t } = useTranslation();
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  return (
    <StyledTableRow
      key={row.index}
      sx={{
        '&:last-child td, &:last-child th': { border: 0 },
        width: '100%',
        background: '#fff',
      }}
    >
      <StyledTableCell sx={{ width: '100%' }}>{row?.signature}</StyledTableCell>
    </StyledTableRow>
  );
};
