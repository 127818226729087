/* eslint-disable max-lines */
import { Loader } from '@/components/common/loader';
import { CommonContainer } from '@/components/layouts/content';
import { BuyForm, TraderType } from '@/components/token/components/buy';
import TabComponent from '@/components/token/components/tabs';
import { RequestStatus } from '@/constants/API';
import useGlobalHook from '@/hooks/useGlobalHook';
import useTokenHook from '@/hooks/useTokenHook';
import { checkIsRequesting } from '@/utils/helper';
import { Alert, Box } from '@mui/material';
import { useEffect, useMemo } from 'react';

export interface DeleteForm {
  input: string;
}
export const PumpfunTrader = () => {
  const {
    requestCreateBuy,
    requestCreateSell,
    createBuyStatus,
    createSellStatus,
    requestResetToken,
    buy,
    sell,
  } = useTokenHook();
  const { setErrorMsg, setSuccessMsg } = useGlobalHook();

  const loading = useMemo(
    () => checkIsRequesting([createBuyStatus, createSellStatus]),
    [createBuyStatus, createSellStatus],
  );

  const onBuy = (formDt: any) => {
    requestCreateBuy(formDt);
  };

  const onSell = (formDt: any) => {
    requestCreateSell(formDt);
  };

  useEffect(() => {
    return () => requestResetToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (createSellStatus === RequestStatus.SUCCESS) {
      setSuccessMsg(['Sold!']);
    }

    if (createSellStatus === RequestStatus.ERROR) {
      setErrorMsg(['Something went wrong. Unable to sell']);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createSellStatus]);

  useEffect(() => {
    if (createBuyStatus === RequestStatus.SUCCESS) {
      setSuccessMsg(['Bought!']);
    }

    if (createBuyStatus === RequestStatus.ERROR) {
      setErrorMsg(['Something went wrong. Unable to buy']);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createBuyStatus]);

  /** fetch data based on query string */

  const tabs = [
    {
      label: 'Pumpfun Buy',
      content: (
        <>
          {buy?.map((it: string, idx: number) => (
            <Alert
              severity="success"
              key={idx}
              sx={{
                margin: '10px 0 30px',
                '& a': {
                  color: '#fff',
                },
              }}
            >
              <a
                href={`https://solscan.io/tx/${it}`}
                target="_blank"
                rel="noreferrer"
              >{`https://solscan.io/tx/${it}`}</a>
            </Alert>
          ))}
          <Box
            sx={{
              position: 'relative',
              maxWidth: '900px',
              margin: '40px auto',
            }}
          >
            <BuyForm onSubmit={onBuy} type={TraderType.BUY} />
          </Box>
        </>
      ),
    },
    {
      label: 'Pumpfun Sell',
      content: (
        <>
          {sell?.map((it: string, idx: number) => (
            <Alert
              severity="success"
              key={idx}
              sx={{
                margin: '10px 0 30px',
                '& a': {
                  color: '#fff',
                },
              }}
            >
              <a
                href={`https://solscan.io/tx/${it}`}
                target="_blank"
                rel="noreferrer"
              >{`https://solscan.io/tx/${it}`}</a>
            </Alert>
          ))}
          <Box
            sx={{
              position: 'relative',
              maxWidth: '900px',
              margin: '40px auto',
            }}
          >
            <BuyForm onSubmit={onSell} type={TraderType.SELL} />
          </Box>
        </>
      ),
    },
  ];

  return (
    <CommonContainer className="top">
      <TabComponent tabs={tabs} />

      <Loader show={loading} />
    </CommonContainer>
  );
};
